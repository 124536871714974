import React, { useState } from 'react'
import TemporaryAccess from '../../assets/temporaryAccess.svg'
import moment from 'moment'
import { useQuery } from 'react-query';
import { UserService } from '../../services/user.service';

function TempAccess() {
    const [search, setSearch] = useState('')
    const { data: RequestRes, isLoading } = useQuery('getTemporaryAccess', UserService.getTemporaryAccess, {
        retry: false,
        refetchOnWindowFocus: false,
        // refetchOnMount: false,
        onError: res => console.log(res),
    })

    return (
        <>
            <div className='flex items-center gap-1.5 font-medium text-2xl px-4 app-blue h-16'>
                <img src={TemporaryAccess} className='h-10 w-9' alt="" />
                <h1 className='capitalize'>Temporary Access</h1>
            </div>
            <div className='px-3'>
                <div className='mt-3 flex items-center justify-end gap-6 pr-3'>
                    <div className='flex items-center gap-2'>
                        <div className='w-4 h-4 rounded-full bg-[#4C3947]'></div>
                        <span className='text-sm font-light'>Coming today</span>
                    </div>
                    <div className='flex items-center gap-2'>
                        <div className='w-4 h-4 rounded-full bg-[#734065]'></div>
                        <span className='text-sm font-light'>Present</span>
                    </div>
                    <div className='flex items-center gap-2'>
                        <div className='w-4 h-4 rounded-full bg-transparent border-2 border-[#734065]'></div>
                        <span className='text-sm font-light'>Sector</span>
                    </div>
                </div>
                <div className='bg-[#2F1127] w-full flex items-center gap-1 py-1 px-2 rounded-full mb-3 mt-1.5'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                    </svg>
                    <input type="text" value={search} onChange={e => setSearch(e.target.value)} placeholder='Search for employee' className='flex-grow p-1 placeholder:text-[#7C6E79] text-sm bg-transparent outline-none border-none font-light' />
                </div>
            </div>
            <div className='flex-grow relative pb-28 flex flex-col gap-2 overflow-y-auto px-3 scroll-hidden'>
                {
                    isLoading
                        ?
                        <></>
                        :
                        RequestRes?.data?.filter(el => el?.name?.toLowerCase()?.includes(search?.toLowerCase()))?.length ?
                            <>
                                {RequestRes?.data?.filter(el => el?.name?.toLowerCase()?.includes(search?.toLowerCase()))?.map((item, i) => <List key={i} item={item} />)}
                            </>
                            :
                            <h1 className='text-2xl mt-10 text-center'>No Activity</h1>
                }
            </div>
        </>
    )
}

export default TempAccess


const List = ({ item }) => {

    return (
        <div className={`${item?.requestType == 'branch' ? new Date(item?.dateFrom) >= new Date() ? 'bg-[#4C3947]' : 'bg-[#734065]' : 'bg-transparent border-2 border-[#734065]'} py-3 px-5 rounded-xl w-full`}>
            <h1 className='text-xl'>
                {item?.name}
            </h1>
            {
                item?.requestType == 'branch'
                    ?
                    <p className='font-extralight text-sm'>{item.branchName}, {item.state}</p>
                    : <p className='font-extralight text-sm'>{item.sector}</p>
            }
            <div className='flex items-center min-w-max gap-1 text-base'>
                <h1>{moment(item.dateFrom).format('DD/MM/YY')}</h1>
                <svg width="30" height="6" viewBox="0 0 30 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.833333 3.11328C0.833333 4.58604 2.02724 5.77995 3.5 5.77995C4.97276 5.77995 6.16667 4.58604 6.16667 3.11328C6.16667 1.64052 4.97276 0.446615 3.5 0.446615C2.02724 0.446615 0.833333 1.64052 0.833333 3.11328ZM29.3319 3.61328C29.608 3.61328 29.8319 3.38942 29.8319 3.11328C29.8319 2.83714 29.608 2.61328 29.3319 2.61328V3.61328ZM3.5 3.61328H29.3319V2.61328H3.5V3.61328Z" fill="white" />
                </svg>
                <p className='text-sm'>{moment(item.dateTo).diff(item.dateFrom, 'days')} Day(s)</p>
                <svg width="30" height="6" viewBox="0 0 30 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.33203 2.61328C1.05589 2.61328 0.832031 2.83714 0.832031 3.11328C0.832031 3.38942 1.05589 3.61328 1.33203 3.61328V2.61328ZM24.4972 3.11328C24.4972 4.58604 25.6911 5.77995 27.1639 5.77995C28.6367 5.77995 29.8306 4.58604 29.8306 3.11328C29.8306 1.64052 28.6367 0.446615 27.1639 0.446615C25.6911 0.446615 24.4972 1.64052 24.4972 3.11328ZM1.33203 3.61328H27.1639V2.61328H1.33203V3.61328Z" fill="white" />
                </svg>
                <h1>{moment(item.dateTo).format('DD/MM/YY')}</h1>
            </div>
            <h1>{item?.assisted?.name}</h1>
        </div>
    )
}