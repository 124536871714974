import React from 'react'
import ProfileIcon from '../../assets/profile.svg'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserInfo, setUserInfo } from '../../slices/userSlice';

function Profile() {
    const userInfo = useSelector(selectUserInfo)
    const dispatch = useDispatch();

    const logout = () => {
        dispatch(setUserInfo(null))
        localStorage.removeItem('app_aqqess_token');
        window.location.replace(userInfo?.branch ? `/branch/${userInfo?.branch}/login` : `/admin/login`)
    }

    return (
        <>
            <div className='flex items-center gap-2 font-medium text-2xl p-4 app-blue h-16'>
                <img src={ProfileIcon} className='h-9' alt="" />
                <h1>Profile Settings</h1>
            </div>
            <div className='flex-grow p-5 overflow-y-auto pb-28'>

                <div className='flex flex-col gap-2'>
                    <div>
                        <h1 className='text-base font-light'>Name</h1>
                        <h1 className='text-xl font-medium'>{userInfo?.name}</h1>
                    </div>
                    <div>
                        <h1 className='text-base font-light'>Office Email</h1>
                        <h1 className='text-xl font-medium'>{userInfo?.email}</h1>
                    </div>
                    <div>
                        <h1 className='text-base font-light'>Employee ID</h1>
                        <h1 className='text-xl font-medium'>{userInfo?.employeeId}</h1>
                    </div>
                    <div>
                        <h1 className='text-base font-light'>Branch</h1>
                        <h1 className='text-xl font-medium'>{userInfo?.branch}, {userInfo?.branchLocation}</h1>
                    </div>
                </div>
                <div className='max-w-max mx-auto space-y-3 my-4'>
                    <button className='py-1.5 px-5 rounded-full bg-[#CBBE47]'>
                        Reset Password
                    </button>
                    <button onClick={logout} className='py-1.5 px-4 rounded-full ml-auto bg-[#CB6747] flex items-center gap-2 justify-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                        </svg>
                        <span>Logout</span>
                    </button>
                </div>

                <div>
                    <h1 className='text-2xl font-medium'>Sector Access</h1>
                    <div className='flex flex-wrap gap-2 text-sm py-2 items-center justify-center max-w-xl mx-auto'>
                        {
                            userInfo?.sectors?.length
                                ?
                                userInfo?.sectors?.map((item, i) => (
                                    <div key={i} className='border-4 rounded-md border-purple-500 py-1 px-2'>
                                        <span>{item.sector}</span>
                                    </div>
                                ))
                                :
                                <h1 className='text-xl font-light mt-10 text-center'>No Sector Assigned</h1>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile