import React, { useEffect, useRef, useState } from 'react'
import Logo from '../../assets/logo.svg'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import OTPInput from 'react-otp-input';
import toast from 'react-hot-toast';
import { useClickOutside } from '../../services/constant'
import { useQuery } from 'react-query';
import { UserService } from '../../services/user.service';

const hasNumber = (str) => /\d/.test(str)
const hasOnlyNumber = (str) => /^\d+$/.test(str)

function SubDomain() {
    const [show, setShow] = useState(false)
    const history = useHistory()
    const [error, setError] = useState(false)
    const [errorBox, setErrorBox] = useState(false)
    const [alpha, setAlpha] = useState('')
    const [numeric, setNumeric] = useState('')
    const [branchName, setBranchName] = useState('')
    const [verified, setVerified] = useState(false)
    const ref = useRef()

    useClickOutside(() => setShow(false), ref)

    useEffect(() => {
        let subdomain = window.location.hostname.split(".")[0];
        if (subdomain.includes("-")) {
            let [a, n] = subdomain.split("-")
            setAlpha(a?.toLowerCase());
            setNumeric(n)
        }
    }, [])

    const { data: valRes, refetch: validateSubDomain } = useQuery('validateSubDomain', () => UserService.validateSubDomain(`${alpha}-${numeric}`), {
        retry: false,
        refetchOnWindowFocus: false,
        enabled: false,
        onSuccess: res => {
            if (!res?.data) {
                setError(true)
                setErrorBox(true)
                setVerified(false);
            }
            else {
                setVerified(true);
                setError(false);
                setErrorBox(false);
                toast.success("Company code verified", { id: 'success' })
            }
        },
        onError: res => console.log(res)
    })

    const accessPortal = () => {
        toast("Verifying...", { id: 'success' })
        validateSubDomain()
        // if (!hasOnlyNumber(numeric)) {
        //     return toast.error("Please provide a valid url", { id: 'error' });
        // }
        // else {
        //     // window.location.replace(`https://${alpha}-${numeric}.emp.aqqess.me`)
        // }
    }
    const proceedLogin = () => {
        window.location.replace(`https://${alpha}-${numeric}.sec.aqqess.me/branch/${branchName}/login`)
    }

    const renderInput = (inputProps) => (
        <>
            <div className='bg-transparent w-full h-10 text-center'>
                <input {...inputProps} className="bg-transparent w-full h-10 outline-none text-2xl" />
                <div className='w-full h-1 bg-[#E8C3FF] rounded-full'></div>
            </div>
        </>
    );
    return (
        <section className='bg-gray flex flex-col gap-7 p-5 h-[100dvh]'>
            <div className='flex items-end cursor-pointer' onClick={() => history.push("/")}>
                <img src={Logo} className='w-10' alt="" />
                <h1 className='text-xl font-medium'>Aqqess</h1>
            </div>
            <div className='w-full max-w-sm mx-auto flex flex-col gap-6'>
                <div>
                    <h1 className='text-2xl sm:text-3xl font-medium text-lavender'>Enter your company code</h1>
                    <p className='text-gray-200 font-extralight text-sm sm:text-base'>If you have misplaced ask a colleague, branch admin</p>
                </div>
                {error ? <p className='text-[#FF0000] text-sm -mt-2 -mb-4 font-extralight'>Looks like you have entered the wrong code.</p> : <></>}
                <div className='relative p-2' onClick={() => setErrorBox(false)}>
                    {errorBox ? <div className='border-2 border-[#FF0000] bg-[#FF0000] bg-opacity-10 absolute inset-0 rounded-lg'></div> : <></>}
                    <div>
                        <h1 className='text-xl'>Alpha Numeric value</h1>
                        <div className='w-full max-w-xs'>
                            <OTPInput
                                value={alpha}
                                onChange={(txt) => setAlpha(txt?.toLowerCase())}
                                numInputs={4}
                                containerStyle="w-full max-w-xs gap-6 px-3 py-1 rounded-lg"
                                renderInput={renderInput}
                            />
                        </div>
                    </div>
                    <div className='mt-6'>
                        <h1 className='text-xl'>Numeric value</h1>
                        <div className='w-full max-w-xs'>
                            <OTPInput
                                value={numeric}
                                onChange={(txt) => setNumeric(txt?.toLowerCase())}
                                numInputs={4}
                                containerStyle="w-full max-w-xs gap-6 px-3 py-1 rounded-lg"
                                renderInput={renderInput}
                            />
                        </div>
                    </div>
                </div>

                <div className='flex justify-end'>
                    {
                        (alpha?.length !== 4 || numeric?.length !== 4)
                            ?
                            <button className='py-1.5 px-6 rounded-xl bg-[#602583] opacity-60'>Access Emp</button>
                            :
                            <button onClick={accessPortal} className='py-1.5 px-6 rounded-xl bg-[#B338FF]'>Access Emp</button>
                    }
                </div>

                <div>
                    <h1 className='text-xl ml-1'>Branch</h1>
                    <div className='relative z-10' ref={ref}>
                        <input type="text" id='branchName' disabled={!verified} onClick={() => setShow(true)} value={branchName} onChange={e => setBranchName(e.target.value)} placeholder={verified ? 'Enter assigned branch name' : 'Verify Company code'} className='py-1.5 px-3 my-1 rounded-xl bg-[#1F0B2C] text-[#6993FF] w-full outline-none border-none' />
                        {
                            branchName?.length && show
                                ?
                                <div className='bg-[#110C2C] absolute top-10 w-full py-2 px-4 rounded-lg'>
                                    {valRes?.data?.branches?.filter(el => el?.name?.toLowerCase()?.includes(branchName?.toLowerCase()))?.slice(0, 5)?.length
                                        ?
                                        valRes?.data?.branches?.filter(el => el?.name?.toLowerCase()?.includes(branchName?.toLowerCase()))?.slice(0, 5)?.map((item, i) => {
                                            return <div key={i} className='flex items-end justify-between border-b-2 last:border-none border-[#5132CD] pb-2'><h1 className='cursor-pointer' onClick={() => { setBranchName(item?.name); setShow(false) }}>{item?.name}</h1> <span className='text-xs font-extralight'>{item?.state}</span></div>
                                        })
                                        :
                                        <h1 className='p-1'>No Matched result</h1>}
                                </div>
                                :
                                <></>}
                    </div>
                    <div className='flex justify-end my-4'>
                        {
                            (alpha?.length !== 4 || numeric?.length !== 4 || !hasOnlyNumber(numeric) || branchName?.length < 3)
                                ?
                                <button className='py-1.5 px-6 rounded-xl bg-[#602583] opacity-60'>Proceed login</button>
                                :
                                <button onClick={proceedLogin} className='py-1.5 px-6 rounded-xl bg-[#B338FF]'>Proceed login</button>
                        }
                    </div>

                </div>
            </div>
        </section>
    )
}

export default SubDomain