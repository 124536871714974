import React from 'react'
import Logo from '../../assets/logo.svg'
import Threadality from '../../assets/threadality.svg'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUserInfo } from '../../slices/userSlice'

function Navbar() {
    const { branchName } = useParams()
    const history = useHistory()
    const userInfo = useSelector(selectUserInfo)
    return (
        <nav className='py-3 px-5 flex items-center justify-between'>
            <div className='flex items-end cursor-pointer' onClick={() => branchName ? history.push(`/branch/${branchName}`) : history.push("/")}>
                <img src={Logo} className='w-10' alt="" />
                <h1 className='text-xl font-medium'>Aqqess</h1>
            </div>

            <div>
                <img src={userInfo?.logo || Threadality} className='h-10' alt="" />
            </div>
        </nav>
    )
}

export default Navbar