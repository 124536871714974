import React from "react";
import { Route, Redirect } from "react-router-dom";
import { decodeToken } from "./services/constant";
import AppLogin from "./components/App/AppLogin";

const UserRoute = ({ component: Component, verified, path, title, ...rest }) => {
    const auth = decodeToken()

    return (
        <Route
            {...rest}
            render={(props) =>
            (
                <Component {...props} />
            )
            }
        />
    );
};

export default UserRoute;
