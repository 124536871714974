import { endpoints } from './config';
import HttpService from './axios-interceptor';

const auth = () => {
  return HttpService.get("/user" + endpoints.auth);
};

const validate = ({ email, employeeId }) => {
  return HttpService.get(`/user` + endpoints.validate + `?email=${email?.trim().toLowerCase()}&employeeId=${employeeId?.trim().toLowerCase()}`);
};

const validateSubDomain = (subDomain) => {
  return HttpService.get("/admin" + endpoints.validateSubDomain + `?subdomain=${subDomain}`);
};

const getCompany = (subdomain) => {
  return HttpService.get("/admin" + endpoints.company + `?subdomain=${subdomain}`);
};
const getEmployee = (id) => {
  if (id)
    return HttpService.get("/user" + endpoints.employee + `?employeeId=${id?.toLowerCase()}`);
};

const sendOtp = data => {
  return HttpService.post(
    "/admin" + endpoints.sendOtp,
    data,
  );
};

const verifyOtp = data => {
  return HttpService.post(
    "/user" + endpoints.verifyOtp,
    data,
  );
};

const login = data => {
  return HttpService.post(
    "/user" + endpoints.login,
    data,
  );
};

const register = data => {
  return HttpService.post(
    "/user" + endpoints.register,
    data,
  );
};

const updateProfile = data => {
  return HttpService.patch(
    "/user" + endpoints.profile,
    data,
  );
};

const resetPassword = data => {
  return HttpService.patch(
    "/user" + endpoints.resetPassword,
    data,
  );
};

const updatePassword = data => {
  return HttpService.patch(
    "/user" + endpoints.updatePassword,
    data,
  );
};

const getSector = () => {
  return HttpService.get(
    "/branch" + endpoints.sector + "s",
  );
};

const generateQr = (assisted) => {
  return HttpService.get(
    "/user" + endpoints.generateQR + `?assisted=${assisted}`,
  );
}

const getTodayActivity = () => {
  return HttpService.get(
    "/user" + endpoints.activity,
  );
}

const getBranches = () => {
  return HttpService.get(
    "/user" + endpoints.company + endpoints.branch + "es",
  );
}

const getTemporaryRequest = () => {
  return HttpService.get(
    "/user" + endpoints.tempReq
  );
}

const temporaryRequest = (data) => {
  return HttpService.post(
    "/user" + endpoints.tempReq,
    data
  );
}

const getTemporaryAccess = () => {
  return HttpService.get(
    "/user" + '/security/tempAccess'
  );
}

const getVisitorRequest = () => {
  return HttpService.get(
    "/user" + '/security/inviteAccess'
  );
}


const updateInvite = data => {
  return HttpService.patch(
    "/user/invite",
    data,
  );
};

export const UserService = {
  validate,
  getCompany,
  auth,
  sendOtp,
  verifyOtp,
  login,
  register,
  updateProfile,
  resetPassword,
  updatePassword,
  getSector,
  generateQr,
  getTodayActivity,
  validateSubDomain,
  getBranches,
  temporaryRequest,
  getTemporaryRequest,
  getEmployee,
  getTemporaryAccess,
  getVisitorRequest,
  updateInvite
};