import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    userInfo: null,
    company: null,
    userSectors: []
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserInfo: (state, action) => {
            state.userInfo = action.payload || null
        },
        setCompany: (state, action) => {
            state.company = action.payload || null
        },
        setUserSectors: (state, action) => {
            state.userSectors = action.payload || []
        }
    },
})

// Action creators are generated for each case reducer function
export const { setUserInfo, setCompany, setUserSectors } = userSlice.actions

export const selectUserInfo = (state) => state.user.userInfo
export const selectUserSectors = (state) => state.user.userSectors
export const selectCompany = (state) => state.user.company

export default userSlice.reducer