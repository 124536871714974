import React from 'react'
import Calendar from '../../assets/calendar.svg'
import { useQuery } from 'react-query'
import { UserService } from '../../services/user.service'
import moment from 'moment/moment'

const formattedDifference = (hours, minutes) => `${hours.toString().padStart(2, '0')} Hr ${minutes.toString().padStart(2, '0')} Mins`;

function TodayActivity() {
    let { data: activityRes, isLoading } = useQuery('getTodayActivity', UserService.getTodayActivity, {
        refetchOnWindowFocus: false,
        retry: false,
    })

    return (
        <>
            <div className='flex items-center gap-2.5 font-medium text-2xl p-4 app-blue h-16'>
                <img src={Calendar} className='h-9' alt="" />
                <h1>Today’s activities</h1>
            </div>

            <div className='flex-grow relative px-3 pt-4 pb-28 flex flex-col gap-2 overflow-y-auto scroll-hidden'>
                {
                    isLoading
                        ?
                        <></>
                        :
                        activityRes?.data?.length ?
                            activityRes?.data?.map((item, i) => <List key={i} item={item} />)
                            :
                            <h1 className='text-2xl mt-10 text-center'>No Activity</h1>
                }
            </div>
        </>
    )
}

export default TodayActivity

const List = ({ item }) => {
    const duration = moment.duration(moment(item.exit).diff(item.entry));
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();

    return (
        <div className={`${item.assisted ? 'border-2 border-[#3474747] bg-transparent' : 'bg-[#474747]'} py-3 px-5 rounded-xl w-full`}>
            <h1 className='text-xl'>
                {item?.sector}
            </h1>
            {
                item?.assisted ?
                    <h1 className='font-extralight italic'>{item?.exit ? `Assisted Exit at: ${moment(item.entry).format('h:mm A')}` : `Assisted Entry at: ${moment(item.entry).format('h:mm A')}`}</h1>
                    :
                    <div className='flex items-center min-w-max gap-1 text-base'>
                        <h1>{moment(item.entry).format('h:mm A')}</h1>
                        <svg width="30" height="6" viewBox="0 0 30 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.833333 3.11328C0.833333 4.58604 2.02724 5.77995 3.5 5.77995C4.97276 5.77995 6.16667 4.58604 6.16667 3.11328C6.16667 1.64052 4.97276 0.446615 3.5 0.446615C2.02724 0.446615 0.833333 1.64052 0.833333 3.11328ZM29.3319 3.61328C29.608 3.61328 29.8319 3.38942 29.8319 3.11328C29.8319 2.83714 29.608 2.61328 29.3319 2.61328V3.61328ZM3.5 3.61328H29.3319V2.61328H3.5V3.61328Z" fill="white" />
                        </svg>
                        {
                            item?.status == 'pending'
                                ?
                                <p className='text-sm text-yellow'>Pending</p>
                                :
                                <>
                                    <p className='text-sm'>{formattedDifference(hours, minutes)}</p>
                                    <svg width="30" height="6" viewBox="0 0 30 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.33203 2.61328C1.05589 2.61328 0.832031 2.83714 0.832031 3.11328C0.832031 3.38942 1.05589 3.61328 1.33203 3.61328V2.61328ZM24.4972 3.11328C24.4972 4.58604 25.6911 5.77995 27.1639 5.77995C28.6367 5.77995 29.8306 4.58604 29.8306 3.11328C29.8306 1.64052 28.6367 0.446615 27.1639 0.446615C25.6911 0.446615 24.4972 1.64052 24.4972 3.11328ZM1.33203 3.61328H27.1639V2.61328H1.33203V3.61328Z" fill="white" />
                                    </svg>
                                    <h1>{moment(item.exit).format('h:mm A')}</h1>
                                </>
                        }
                    </div>
            }
            {item?.assisted?.name ? <p>{item?.assisted?.name}</p> : ''}
        </div>
    )
}