import React from 'react'
import Logo from '../../assets/logo.svg'

function Loader() {
    return (
        <section className='flex flex-col items-center justify-center bg-1 w-full min-h-screen gap-3'>
            <img src={Logo} className='w-full h-40' alt="" />
            <h1 className='text-3xl font-medium'>Loading...</h1>
        </section>
    )
}

export default Loader