import React from 'react'
import Logo from '../../assets/logo.svg'
import Rain from '../../assets/rain.png'
import { useHistory } from 'react-router-dom'
import { decodeToken } from '../../services/constant'

function NotFound() {
    const history = useHistory()
    let auth = decodeToken();

    return (
        <section className='bg-[#3B1628] flex flex-col w-full h-[100dvh] overflow-hidden'>
            <nav className='py-3 px-5 flex items-center justify-between'>
                <div className='flex items-end cursor-pointer' onClick={() => history.push("/")}>
                    <img src={Logo} className='w-10' alt="" />
                    <h1 className='text-xl font-medium'>Aqqess</h1>
                </div>
            </nav>
            <div className='flex-grow w-full max-w-sm p-5 flex flex-col gap-5 mx-auto'>
                <div className='text-right space-y-2'>
                    <h1 className='text-4xl sm:text-5xl font-bold'>Lost Aqqess</h1>
                    <button className='py-1.5 px-4 rounded-xl bg-[#FF63DD]' onClick={() => history.push(auth?.branchName ? `/branch/${auth?.branchName}` : "/")}>Return to home</button>
                </div>
                <img src={Rain} className='h-full p-5' alt="" />
            </div>
        </section>
    )
}

export default NotFound