import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';
import { Toaster } from 'react-hot-toast';
import HttpService from './services/axios-interceptor';
import Landing from './components/LandingPage/Landing';

const isMobileDevice = () => {
  const userAgent = window.navigator.userAgent;
  const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return mobileRegex.test(userAgent);
};

function App() {
  HttpService.configure();

  if (!isMobileDevice())
    return <Landing />

  return (
    <Router>
      <div><Toaster /></div>
      <Routes />
    </Router>
  );
}

export default App;
