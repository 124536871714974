import axios from 'axios';
import { API_URL } from './config';

const _axios = axios.create({
  baseURL: `${API_URL}`,
  timeout: 30000,
});

const configure = () => {
  _axios.interceptors.request.use(async (config) => {
    const jwtToken = localStorage.getItem('app_aqqess_token')

    config.headers['Content-Type'] = 'application/json';

    if (jwtToken) {
      config.headers['Authorization'] = `Bearer ${jwtToken}`;
    }

    return config;
  });
  _axios.interceptors.response.use(
    response => {
      let data = response?.data || response?.response?.data;
      if (response)
        response.data = data
      if (typeof response.success == 'boolean')
        return response
      return response.data;
    },
    error => {
      const { data = {}, status, statusText } = error?.response || {};
      data.message = data?.message || data.error || statusText;
      data.statusCode = data.statusCode || status;
      return data;
    },
  );
};

const getAxiosClient = () => _axios;

const HttpService = {
  configure,
  getAxiosClient,
  get: getAxiosClient().get,
  post: getAxiosClient().post,
  put: getAxiosClient().put,
  patch: getAxiosClient().patch,
  delete: getAxiosClient().delete,
};

export default HttpService;